import React, { useMemo, useState, createContext, useContext, useRef, useEffect } from 'react';
import AxiosRequests from '../api/axiosRequests';
import { useAxiosInstance } from '../utils/Axios/axiosInstance';
import { useKeycloak } from './Keycloak';
import config from '../utils/config';

const SESSION_STATUS = {
  UNREGISTERED: 'UNREGISTERED',
  REGISTERED: 'REGISTERED',
};

const ACCESS_STATUS = {
  PENDING: 'PENDING',
  FETCHED: 'FETCHED',
};

const PnrAccessContext = createContext({});

const PnrAccessProvider = ({ children }) => {
  const [userAccess, setUserAccess] = useState(null);
  const [sessionStatus, setSessionStatus] = useState(SESSION_STATUS.UNREGISTERED);
  const [accessStatus, setAccessStatus] = useState(ACCESS_STATUS.PENDING);
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const sessionId = useRef(keycloak.sessionId);

  const updateUserAccess = (newUserAccess) => {
    setUserAccess(newUserAccess);
    setAccessStatus(ACCESS_STATUS.FETCHED);
  };

  const registerSession = async () => {
    const response = await AxiosRequests.registerSession(apiClient);
    setSessionStatus(SESSION_STATUS.REGISTERED);
    updateUserAccess(response.data);
  };

  const registerIfRequired = () => {
    if (sessionStatus === SESSION_STATUS.REGISTERED) {
      return Promise.resolve();
    }

    return registerSession();
  };

  const fetchUserAccess = async () => {
    const response = await AxiosRequests.pnrAccessPrivilege(apiClient);
    updateUserAccess(response.data);
  };

  const fetchUserAccessIfPending = () => {
    if (accessStatus === ACCESS_STATUS.PENDING) {
      return fetchUserAccess();
    }
    return Promise.resolve();
  };

  const registerIfRequiredThenFetchUserAccessIfPending = () => {
    return registerIfRequired().then(fetchUserAccessIfPending);
  };

  const requestPnrAccess = async (payload) => {
    return AxiosRequests.initialPnrForm(apiClient, payload)
      .then((response) => {
        updateUserAccess(response.data);
      });
  };

  useEffect(() => {
    const newSessionId = keycloak.sessionId;

    /**
     * This will probably never happen because in order to have a new session
     * the user will probably have to come from the login page which is on a
     * different site but for completeness sake I've refreshed all this
     */
    if (sessionId.current !== newSessionId) {
      setSessionStatus(SESSION_STATUS.UNREGISTERED);
      setAccessStatus(ACCESS_STATUS.PENDING);
      setUserAccess({});
      registerSession();
    }

    sessionId.current = keycloak.sessionId;
  }, [keycloak]);

  const value = useMemo(() => ({
    userAccess,
    fetchUserAccess: registerIfRequiredThenFetchUserAccessIfPending,
    requestPnrAccess,
  }), [
    userAccess,
    registerIfRequiredThenFetchUserAccessIfPending,
    requestPnrAccess,
  ]);

  return (
    <PnrAccessContext.Provider value={value}>{children}</PnrAccessContext.Provider>
  );
};

const usePnrAccess = () => useContext(PnrAccessContext);

export { PnrAccessContext, PnrAccessProvider, usePnrAccess };
