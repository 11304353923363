import { FORM_ACTIONS } from '../../utils/constants';

export const TARGET_RECIPIENT_ID = 'targetRecipient';

export default {
  id: 'targetRecipient',
  version: '1.0.0',
  name: 'targetRecipient',
  title: 'Target recipient',
  type: 'form',
  components: [],
  pages: [{
    id: 'targetRecipient',
    name: 'targetRecipient',
    components: [
      {
        id: 'recipOptions',
        fieldId: 'recipOptions',
        label: 'Select a type of recipient to add',
        type: 'checkboxes',
        data: {
          options: [
            {
              value: 'FRONTLINE_TEAM',
              label: 'Frontline team',
              nested: [
                {
                  id: 'FRONTLINE_TEAM',
                  fieldId: 'FRONTLINE_TEAM',
                  label: '',
                  'aria-label': 'Select another frontline team to add',
                  type: 'autocomplete',
                  required: true,
                  clearable: true,
                  openOnClick: true,
                  placeholder: 'Select another frontline team to add',
                  item: {
                    value: 'customName',
                    label: 'customName',
                  },
                  custom_errors: [
                    {
                      type: 'required',
                      message: 'Select a frontline team to send the target to',
                    },
                  ],
                },
                {
                  id: 'frontlineRecipients',
                  fieldId: 'frontlineRecipients',
                  type: 'collection',
                  labels: {
                    item: '',
                    add: 'Add another frontline officer',
                  },
                  countOffset: 1,
                  focusOnAdd: 1,
                  item: [
                    {
                      id: 'FRONTLINE_TEAM',
                      fieldId: 'FRONTLINE_TEAM',
                      label: '',
                      'aria-label': 'Select another frontline team to add',
                      type: 'autocomplete',
                      required: true,
                      clearable: true,
                      openOnClick: true,
                      placeholder: 'Select another frontline team to add',
                      item: {
                        value: 'customName',
                        label: 'customName',
                      },
                      custom_errors: [
                        {
                          type: 'required',
                          message: 'Select a frontline team to send the target to',
                        },
                      ],
                    },

                  ],
                },
              ],
            },
            {
              value: 'BORDER_OFFICER',
              label: 'UK Border Force officer',
              nested: [
                {
                  id: 'BORDER_OFFICER',
                  fieldId: 'BORDER_OFFICER',
                  label: '',
                  'aria-label': 'Select border force officer to add',
                  type: 'autocomplete',
                  required: true,
                  clearable: true,
                  openOnClick: true,
                  placeholder: 'Select border force officer to add',
                  data: {
                    url: '/cop-targeting-api/v2/users/front-line-officers',
                  },
                  item: {
                    value: 'email',
                    label: 'email',
                  },
                  custom_errors: [
                    {
                      type: 'required',
                      message: 'Select a border force officer to send the target to',
                    },
                  ],
                },
                {
                  id: 'borderOfficers',
                  fieldId: 'borderOfficers',
                  type: 'collection',
                  labels: {
                    item: '',
                    add: 'Add another border force officer',
                  },
                  countOffset: 1,
                  focusOnAdd: 1,
                  item: [
                    {
                      id: 'BORDER_OFFICER',
                      fieldId: 'BORDER_OFFICER',
                      label: '',
                      'aria-label': 'Select border force officer to add',
                      type: 'autocomplete',
                      required: true,
                      clearable: true,
                      openOnClick: true,
                      placeholder: 'Select border force officer to add',
                      data: {
                        url: '/cop-targeting-api/v2/users/front-line-officers',
                      },
                      item: {
                        value: 'email',
                        label: 'email',
                      },
                      custom_errors: [
                        {
                          type: 'required',
                          message: 'Select a border force officer to send the target to',
                        },
                      ],
                    },
                  ],
                },

              ],
            },
          ],
        },
        required: true,
        custom_errors: [
          {
            type: 'required',
            message: 'Select a type of recipient to add',
          },
        ],
      },

    ],
    actions: [
      {
        type: FORM_ACTIONS.SUBMIT,
        validate: true,
        label: 'Save and close',
      },
      {
        type: FORM_ACTIONS.CANCEL,
        label: 'Cancel',
        classModifiers: 'secondary',
      },
    ],
  }],
};
