// Global import(s)
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useNavigate } from 'react-router-dom';

// Config(s)
import { LOCAL_STORAGE_KEYS } from '../../../../utils/constants';
import { GROUP_DROPDOWN_OPTIONS } from '../constants';
import { SORT_ORDER } from '../../../Task/TaskList/constants';

// Context(s)
import { useKeycloak } from '../../../../context/Keycloak';
import { useNotification } from '../../../../context/NotificationContext';
import { useTabs } from '../../../../context/TabContext';
import { useTeam } from '../../../../context/TeamContext';
import { useUser } from '../../../../context/UserContext';
import { useView } from '../../../../context/ViewContext';

// Hook(s)
import useFetchTargets from '../../../../utils/Hooks/useFetchTargets';
import useFetchGroupCounts from '../../../../utils/Hooks/useFetchGroupCounts';
import useFetchTargetsTabCount from '../../../../utils/Hooks/useFetchTargetsTabCount';

// Utils(s)
import toFilterParams, { toStatusCountParams } from '../helper/filters/toFilterParams';
import { StorageUtil, TargetGroupUtil } from '../../../../utils';

const useTargetsList = () => {
  const keycloak = useKeycloak();
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();
  const { clearTabNotification } = useNotification();
  const { DEFAULTS, selectedTab, setTabIndex, setSelectedTab } = useTabs();
  const { teamGroup } = useTeam();
  const { userGroups, isInterceptingOfficer } = useUser();
  const { view } = useView();
  const currentUser = keycloak?.tokenParsed?.email || null;

  const [groupCodes, setGroupCodes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [sortParamsOrder, setSortParamsOrder] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.SORT_ORDER) ?? SORT_ORDER.ASC);
  const [showSortParams, setShowSortParams] = useState(false);
  const filtersRef = useRef();

  // PAGINATION SETTINGS
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 20;

  const applySavedFiltersOnLoad = () => {
    const cachedFilters = JSON.parse(localStorage.getItem(DEFAULTS[view]?.filters?.key))
      ?? DEFAULTS[view]?.filters?.default;
    setAppliedFilters(cachedFilters);
  };

  if (!selectedTab) {
    if (view.match(/AIRPAX/g)) {
      setSelectedTab('incoming');
    }
    if (view.match(/RORO/g)) {
      setSelectedTab('assigned');
    }
  }

  // This would cause an error.
  if (appliedFilters === null) {
    applySavedFiltersOnLoad();
  }

  const filterParams = useMemo(() => {
    return toFilterParams({
      itemsPerPage,
      activePage,
      appliedFilters,
      sortParamsOrder,
      currentUser,
      teamGroup,
      groupCodes,
      selectedTab,
      view,
    });
  }, [view, selectedTab, groupCodes, teamGroup, sortParamsOrder, appliedFilters, activePage]);

  const statusCountParams = useMemo(() => {
    // #17952 Because appliedFilters isn't created fast enough we have to add it
    // here manually just in case;
    const cachedFilters = JSON.parse(localStorage.getItem(DEFAULTS[view]?.filters?.key))
      ?? DEFAULTS[view]?.filters?.default;
    return [
      ...toStatusCountParams({
        itemsPerPage,
        activePage,
        appliedFilters: appliedFilters === null ? cachedFilters : appliedFilters,
        sortParamsOrder,
        currentUser,
        teamGroup,
        groupCodes,
        selectedTab,
        view,
      }),
    ];
  }, [view, selectedTab, groupCodes, teamGroup, sortParamsOrder, appliedFilters, activePage]);

  const { targets, totalTargets, isLoading: isLoadingTargets } = useFetchTargets(filterParams, !!groupCodes?.length || isInterceptingOfficer);
  const { targetsTabCounts, isLoading: isLoadingTabCounts } = useFetchTargetsTabCount(statusCountParams, selectedTab, !!groupCodes?.length || isInterceptingOfficer);
  const { groupCounts, isLoading: isLoadingGroupCounts } = useFetchGroupCounts(
    [{ ...filterParams?.filterParams, groupCodes }],
    !!groupCodes?.length || isInterceptingOfficer,
  );

  const isReturnFromTargetInfo = () => {
    return StorageUtil.get(LOCAL_STORAGE_KEYS.TARGET_INFO_PATH) !== null;
  };

  // #13914 on clicking 'back' from Record Outcome page
  const isReturnFromRecordOutcome = () => {
    return StorageUtil.get(LOCAL_STORAGE_KEYS.RECORD_OUTCOME_PATH) !== null;
  };

  /**
   * On Back action, clear cached tab depending on the return location determined
   * from the value in local storage.
   */
  window.onpopstate = () => {
    if (isReturnFromTargetInfo()) {
      StorageUtil.remove(LOCAL_STORAGE_KEYS.TARGET_INFO_PATH);
      return;
    }

    if (isReturnFromRecordOutcome()) {
      StorageUtil.remove(LOCAL_STORAGE_KEYS.RECORD_OUTCOME_PATH);
    }
  };

  const onShowOrHideFilters = () => {
    if (!showFilters) {
      filtersRef.current.style.setProperty('display', 'block', 'important');
      filtersRef.current.style.setProperty('visibility', 'visible', 'important');
    } else {
      filtersRef.current.style.setProperty('display', 'none');
      filtersRef.current.style.setProperty('visibility', 'hidden');
    }
    setShowFilters(!showFilters);
  };

  const onModeChange = (e) => {
    const { value } = e.target;
    const selectedOption = GROUP_DROPDOWN_OPTIONS.find((opt) => opt.value === value);
    setTabIndex(0);
    setSelectedTab(selectedOption?.defaultTab);
    clearTabNotification();
    navigate(selectedOption?.targetsListPath);

    localStorage.setItem(LOCAL_STORAGE_KEYS.PREVIOUS_KNOWN_LOCATION, selectedOption?.targetsListPath);
  };

  const onSortOrderChange = (e) => {
    const { value } = e.target;
    setSortParamsOrder(value);
    StorageUtil.add(LOCAL_STORAGE_KEYS.SORT_ORDER, value);
  };

  const resetActivePage = () => setActivePage(1);

  const onApplyFilter = (payload) => {
    resetActivePage();
    setAppliedFilters(payload);
    StorageUtil.add(DEFAULTS[view]?.filters?.key, JSON.stringify(payload));
  };

  const onClearFilter = () => {
    resetActivePage();
    setAppliedFilters(DEFAULTS[view]?.filters?.default);
    StorageUtil.remove(DEFAULTS[view]?.filters?.key);
  };

  const onTabClick = async (item, itemIndex) => {
    if (selectedTab === item.id) {
      return;
    }
    setTabIndex(itemIndex);
    setSelectedTab(item.id);
    clearTabNotification();
    onClearFilter();
  };

  useEffect(() => {
    trackPageView();
    applySavedFiltersOnLoad();
  }, [view]);

  useDeepCompareEffect(() => {
    const _groups = TargetGroupUtil.groups(userGroups, DEFAULTS[view]?.groupExtractors);
    setGroups(_groups);
    setGroupCodes(TargetGroupUtil.groupCodes(_groups));
  }, [userGroups, view]);

  useLayoutEffect(() => {
    setShowSortParams(!!targets?.length);
  }, [targets]);

  return {
    targets,
    totalTargets,
    targetsTabCounts,
    groupCounts,
    isLoadingTargets,
    isLoadingTabCounts,
    isLoadingGroupCounts,
    itemsPerPage,
    activePage,
    setActivePage,
    showSortParams,
    sortParamsOrder,
    appliedFilters,
    filtersRef,
    currentUser,
    groups,
    showFilters,
    onShowOrHideFilters,
    onSortOrderChange,
    onModeChange,
    onTabClick,
    onApplyFilter,
    onClearFilter,
  };
};

export default useTargetsList;
