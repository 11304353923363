import { API_VERSIONS } from '../utils/constants';

const toBulkDismissalsUrl = () => {
  return '/v2/targeting-tasks/bulk-dismissals';
};

/**
 * Get PNR elements.
 *
 * @param axiosClients An axios client for a network request.
 * @param taskId The id of the task.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getPnrElements = async (axiosClient, taskId) => {
  try {
    return await axiosClient.get(`/v2/targeting-tasks/${taskId}/information-sheet/pnr-elements`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get tasks list data.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTaskList = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targeting-tasks/pages', payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get tasks list ids.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTaskListIds = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targeting-tasks/pages/ids', payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get previous tasks list data.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getOtherTaskList = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/other-targeting-tasks/pages', payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get task data.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param justificationId The justification id for unmasking (optional).
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTaskData = async (axiosClient, taskId, justificationId = null) => {
  try {
    const queryParams = justificationId ? `?justification-id=${justificationId}` : '';
    return await axiosClient.get(`/v2/targeting-tasks/${taskId}${queryParams}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get target sheet data.
 *
 * @param axiosClient An axios client for a network request.
 * @param targetId The id of the target.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTargetSheet = async (axiosClient, targetId) => {
  try {
    return await axiosClient.get(`/v2/targets/${targetId}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get task data by movement id.
 *
 * @param axiosClient An axios client for a network request.
 * @param movementId The movement id of the task.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTaskDataByMovementId = async (axiosClient, movementId) => {
  try {
    return await axiosClient.get(`/v2/targeting-tasks?movement-id=${movementId}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get previous task data.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param justificationId The justification id for unmasking (optional)
 * @returns {Promise<*>} A fulfilled promise.
 */
const getPreviousTaskData = async (axiosClient, taskId, justificationId) => {
  try {
    const queryParams = justificationId ? `?justification-id=${justificationId}` : '';
    return await axiosClient.get(`/v2/previous-targeting-tasks/${taskId}${queryParams}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Update target
 *
 * @param {*} axiosClient An axios client for network request.
 * @param {string} targetId The target id.
 * @param {*} payload The request parameters
 * @returns {Promise<*>} A fulfilled promise.
 */
const updateIssuedTarget = async (axiosClient, targetId, payload) => {
  try {
    return await axiosClient.put(`/v4/targets/${targetId}`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get task filter data - this gets all filter data for a task page (for use in autocomplete typeahead).
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTaskFilterCountData = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targeting-tasks/filter-counts', payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetch the target information sheet data.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getInformationSheet = async (axiosClient, taskId) => {
  try {
    return await axiosClient.get(`/v2/targeting-tasks/${taskId}/information-sheets`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Save notes to a task.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const saveTaskNote = async (axiosClient, taskId, payload) => {
  try {
    return await axiosClient.post(`/v2/targeting-tasks/${taskId}/notes`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submit the target information sheet form.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {*} payload The request parameters.
 * @param {string} apiVersion The api version to use e.g. v2, v3 (when this is not provided, it will default to v2).
 * @returns {Promise<*>} A fulfilled promise.
 */
const submitTargetInformationSheet = async (axiosClient, payload, apiVersion = API_VERSIONS.V2) => {
  try {
    return await axiosClient.post(`/${apiVersion}/targets`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submits the task completion form.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const completeTargetingTask = async (axiosClient, taskId, payload, apiVersion = API_VERSIONS.V3) => {
  try {
    return await axiosClient.post(`/${apiVersion}/targeting-tasks/${taskId}/completions`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submits the task dismissal form.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const dismissTargetingTask = async (axiosClient, taskId, payload) => {
  try {
    return await axiosClient.post(`/v2/targeting-tasks/${taskId}/dismissals`, payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Bulk dismisses tasks.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const dismissTargetingTasks = async (axiosClient, payload) => {
  try {
    return await axiosClient.post(toBulkDismissalsUrl(), payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Adds tasks to the bulk dismissal list.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const stageTaskDismissal = async (axiosClient, payload) => {
  try {
    return await axiosClient.put(toBulkDismissalsUrl(), payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Removes a task from the bulk dismissal list.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const unStageTaskDismissal = async (axiosClient, payload) => {
  try {
    return await axiosClient.delete(toBulkDismissalsUrl(), { data: payload });
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submits the initial PNR justification  form.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const submitInitialPnrAccessForm = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/passenger-name-record-access-requests', payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get the current user Pnr data access privilege.
 *
 * @param axiosClient An axios client for a network request.
 * @returns {Promise<*>} A fulfilled promise.
 */
const checkUserPnrDataAccessPrivilege = async (axiosClient) => {
  try {
    return await axiosClient.get('/v2/passenger-name-record-access-requests');
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submits the task relist form.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const relistTargetingTask = async (axiosClient, taskId, payload) => {
  try {
    return await axiosClient.post(`/v2/targeting-tasks/${taskId}/relist`, payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submits the target withdrawn form.
 *
 * @param axiosClient An axios client for a network request.
 * @param targetId The id of the target
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const withdrawTargetingTask = async (axiosClient, targetId, payload) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/withdraw`, payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Post keycloak session to the justification service via the cop-targeting-api.
 *
 * @param axiosClient An axios client for a network request.
 * @returns {Promise<*>} A fulfilled promise.
 */
const registerKeycloakSession = async (axiosClient) => {
  try {
    return await axiosClient.post('/v2/passenger-name-record-access-requests/sessions');
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get refdata.
 *
 * @param axiosClient An axios client for a network request.
 * @param url The URL to fetch data from.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getRefData = async (axiosClient, url, payload = {}) => {
  try {
    return await axiosClient.get(url, payload)
      .then((response) => response.data.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

const deleteTaskById = async (axiosClient, taskId) => {
  try {
    return await axiosClient.delete(`/v2/targeting-tasks/${taskId}`);
  } catch (e) {
    throw new Error(e.message);
  }
};

const getMovementRecordByVersion = async (axiosClient, taskId, versionNumber) => {
  try {
    return await axiosClient.get(`/v2/movement-records?task-id=${taskId}&version-number=${versionNumber}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

const getApiInfo = async (axiosClient) => {
  try {
    return await axiosClient.get('/actuator/info')
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

const migrateAllTasks = async (axiosClient) => {
  try {
    return await axiosClient.post('/v2/data-migrations/targeting-tasks', {});
  } catch (e) {
    throw new Error(e.message);
  }
};

const migrateAllTargets = async (axiosClient) => {
  try {
    return await axiosClient.post('/v2/data-migrations/targets', {});
  } catch (e) {
    throw new Error(e.message);
  }
};

const clearDownTargets = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targets/clear-down', payload);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get data as a blob.
 *
 * @param axiosClient An axios client for a network request.
 * @param url The URL to fetch data from.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getDataAsBlob = async (axiosClient, url) => {
  try {
    return await axiosClient.get(url, { responseType: 'blob' })
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get justifications against the user.
 *
 * @param axiosClient An axios client for a network request.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getJustifications = async (axiosClient) => {
  try {
    return await axiosClient.get('/v2/passenger-name-record-access-requests/unmask-justifications')
      .then((response) => {
        return {
          data: response.data,
          pendingApprovals: parseInt(response.headers['x-pending-justifications-count'], 10) || 0,
        };
      });
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get eab data associated with the supplied taskId.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getBorderEventData = async (axiosClient, taskId) => {
  try {
    return await axiosClient.get(`/v2/border-events/${taskId}/form-submissions`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get form JSON.
 *
 * @param axiosClient An axios client for a network request.
 * @param url The URL to the form json.
 * @returns {Promise<*>} A fulfilled promise.
 */
const fetchForm = async (axiosClient, url) => {
  try {
    return await axiosClient.get(url)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Claim a task
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @returns {Promise<*>} A fulfilled promise.
 */
const claimTask = async (axiosClient, taskId) => {
  try {
    return await axiosClient.post(`/v2/targeting-tasks/${taskId}/claim`);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Unclaim a task
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @returns {Promise<*>} A fulfilled promise.
 */
const unclaimTask = async (axiosClient, taskId) => {
  try {
    return await axiosClient.post(`/v2/targeting-tasks/${taskId}/unclaim`);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Add task detail
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const addTaskDetail = async (axiosClient, taskId, payload) => {
  try {
    return await axiosClient.post(`/v2/targeting-tasks/${taskId}/additional-contents`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Removes an uploaded photo from the file upload server.
 *
 * @param axiosClient An axios client for a network request.
 * @param url The URL to the file resource.
 * @returns {Promise<*>} A fulfilled promise.
 */
const deleteUploadedPhotograph = async (axiosClient, url) => {
  try {
    return await axiosClient.delete(url);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Update a note on a task.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param noteId The id of the note to be edited.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const updateTaskNote = async (axiosClient, taskId, noteId, payload) => {
  try {
    return await axiosClient.put(`/v2/targeting-tasks/${taskId}/notes/${noteId}`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Delete a note on a task.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param noteId The id of the note to be deleted.
 * @returns {Promise<*>} A fulfilled promise.
 */
const deleteTaskNote = async (axiosClient, taskId, noteId) => {
  try {
    return await axiosClient.delete(`/v2/targeting-tasks/${taskId}/notes/${noteId}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Delete a content on a task.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param contentId The id of the content to be deleted.
 * @returns {Promise<*>} A fulfilled promise.
 */
const deleteTaskContent = async (axiosClient, taskId, contentId) => {
  try {
    return await axiosClient.delete(`/v2/targeting-tasks/${taskId}/additional-contents/${contentId}`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Update a previously added entity content.
 *
 * @param axiosClient An axios client for a network request.
 * @param taskId The id of the task.
 * @param contentId The id of the content to be updated.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const updateTaskContent = async (axiosClient, taskId, contentId, payload) => {
  try {
    return await axiosClient.put(`/v2/targeting-tasks/${taskId}/additional-contents/${contentId}`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Save credibility checks, PNC checks and additional content to a task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {*} taskId The id of the task.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const saveCredibilityChecksAndAdditionalContent = async (axiosClient, taskId, payload) => {
  try {
    return await axiosClient.patch(`/v2/targeting-tasks/${taskId}`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Withdraws the issued target and completes the task.
 *
 * @param axiosClient An axios client for a network request.
 * @param targetId The id of the task.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const withdrawTargetAndCompleteTask = async (axiosClient, targetId, payload) => {
  try {
    return await axiosClient.post(`/v3/targeting-tasks/${targetId}/target-withdrawal-completions`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get user details.
 *
 * @param axiosClient An axios client for a network request.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getUserDetails = async (axiosClient) => {
  try {
    return await axiosClient.get('/v2/users')
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get targets list data.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTargetsList = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targets/journeys/pages', payload)
      .then((response) => {
        return {
          data: response.data,
          totalTargets: parseInt(response.headers['x-total-count'], 10) || 0,
        };
      });
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get targets group counts.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTargetsGroupCounts = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targets/group-counts', payload)
      .then((response) => response.data?.[0]?.groupCounts?.groupCounts || []);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get targets tab counts.
 *
 * @param axiosClient An axios client for a network request.
 * @param payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getTargetsTabCounts = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/targets/status-counts', payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Receipt a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @returns {Promise<*>} A fulfilled promise.
 */
const receiptTargetTask = async (axiosClient, targetId) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/receipt`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Receipt a RCCU target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @returns {Promise<*>} A fulfilled promise.
 */
const receiptRccuTargetTask = async (axiosClient, targetId) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/rccu-receipt`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Capture a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @returns {Promise<*>} A fulfilled promise.
 */
const captureTargetTask = async (axiosClient, targetId) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/capture`);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Un-Capture a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @returns {Promise<*>} A fulfilled promise.
 */
const unCaptureTargetTask = async (axiosClient, targetId) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/uncapture`);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Accept a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @returns {Promise<*>} A fulfilled promise.
 */
const acceptTargetTask = async (axiosClient, targetId) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/accept`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submit a target feedback for a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const submitTargetTaskFeedback = async (axiosClient, targetId, payload) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/front-line-action`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Assign a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const assignTargetTask = async (axiosClient, targetId, payload) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/assign`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * ReAssign a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const reassignTargetTask = async (axiosClient, targetId, payload) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/reassign`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Get a list of frontline officers
 *
 * @param axiosClient An axios client for a network request.
 * @returns {Promise<*>} A fulfilled promise.
 */
const getFrontlineOfficers = async (axiosClient) => {
  try {
    return await axiosClient.get('/v2/users/front-line-officers')
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Create a general aviation task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const createGeneralAviationTask = async (axiosClient, payload) => {
  try {
    return await axiosClient.post('/v2/movements', payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Update a general aviation task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {*} taskId The id of the task.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const updateGeneralAviationTask = async (axiosClient, taskId, payload) => {
  try {
    return await axiosClient.put(`/v2/movements/${taskId}`, payload)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Bulk Delete task.
 *
 * @param axiosClient An axios client for a network request.
 * @param {*} payload The request parameters.
 * @returns {Promise<*>} A fulfilled promise.
 */
const bulkDeleteTask = async (axiosClient, payload) => {
  try {
    return await axiosClient.delete('/v2/targeting-tasks/rules', { data: payload })
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Submit for X-Ray analysis on a target task.
 *
 * @param {*} axiosClient An axios client for a network request.
 * @param {string} targetId The target id.
 * @returns {Promise<*>} A fulfilled promise.
 */
const submitForXrayAnalysis = async (axiosClient, targetId) => {
  try {
    return await axiosClient.post(`/v2/targets/${targetId}/xray-analysis-requests`)
      .then((response) => response.data);
  } catch (e) {
    throw new Error(e.message);
  }
};

const cancelAxiosRequests = (source) => {
  source.cancel('Cancelling request');
};

const AxiosRequests = {
  acceptTarget: acceptTargetTask,
  addDetail: addTaskDetail,
  assignTarget: assignTargetTask,
  blobData: getDataAsBlob,
  borderEventData: getBorderEventData,
  bulkDelete: bulkDeleteTask,
  cancel: cancelAxiosRequests,
  captureTarget: captureTargetTask,
  claim: claimTask,
  completeTask: completeTargetingTask,
  createGaTask: createGeneralAviationTask,
  createTarget: submitTargetInformationSheet,
  deleteById: deleteTaskById,
  deleteNote: deleteTaskNote,
  deletePhoto: deleteUploadedPhotograph,
  deleteContent: deleteTaskContent,
  dismissTask: dismissTargetingTask,
  dismissTasks: dismissTargetingTasks,
  fetchRefData: getRefData,
  frontlineOfficers: getFrontlineOfficers,
  getForm: fetchForm,
  getOtherTasks: getOtherTaskList,
  getTargets: getTargetsList,
  getTargetsGroupCounts,
  getTargetsTabCounts,
  getTaskIds: getTaskListIds,
  getTasks: getTaskList,
  getTaskFilterCounts: getTaskFilterCountData,
  informationSheet: getInformationSheet,
  initialPnrForm: submitInitialPnrAccessForm,
  justifications: getJustifications,
  migrateTargets: migrateAllTargets,
  migrateTasks: migrateAllTasks,
  movementRecordByVersion: getMovementRecordByVersion,
  apiInfo: getApiInfo,
  pnrAccessPrivilege: checkUserPnrDataAccessPrivilege,
  pnrElements: getPnrElements,
  previousTaskData: getPreviousTaskData,
  reassignTarget: reassignTargetTask,
  receiptRccuTarget: receiptRccuTargetTask,
  receiptTarget: receiptTargetTask,
  registerSession: registerKeycloakSession,
  relistTask: relistTargetingTask,
  saveChecks: saveCredibilityChecksAndAdditionalContent,
  saveNote: saveTaskNote,
  stageDismissal: stageTaskDismissal,
  targetFeedback: submitTargetTaskFeedback,
  targetSheet: getTargetSheet,
  targetsClearDown: clearDownTargets,
  taskData: getTaskData,
  taskDataByMovementId: getTaskDataByMovementId,
  unCaptureTarget: unCaptureTargetTask,
  unStageDismissal: unStageTaskDismissal,
  unclaim: unclaimTask,
  updateGaTask: updateGeneralAviationTask,
  updateContent: updateTaskContent,
  updateNote: updateTaskNote,
  updateTarget: updateIssuedTarget,
  userDetails: getUserDetails,
  withdrawAndCompleteTask: withdrawTargetAndCompleteTask,
  withdrawTask: withdrawTargetingTask,
  xrayAnalysis: submitForXrayAnalysis,
};

export default AxiosRequests;

export {
  acceptTargetTask,
  addTaskDetail,
  bulkDeleteTask,
  cancelAxiosRequests,
  captureTargetTask,
  checkUserPnrDataAccessPrivilege,
  claimTask,
  clearDownTargets,
  completeTargetingTask,
  createGeneralAviationTask,
  deleteTaskContent,
  deleteTaskNote,
  deleteUploadedPhotograph,
  dismissTargetingTask,
  dismissTargetingTasks,
  fetchForm,
  getBorderEventData,
  getDataAsBlob,
  getFrontlineOfficers,
  getInformationSheet,
  getJustifications,
  getPnrElements,
  getPreviousTaskData,
  getRefData,
  getTaskData,
  getTaskDataByMovementId,
  getTaskFilterCountData,
  getTaskList,
  getTaskListIds,
  getUserDetails,
  migrateAllTargets,
  migrateAllTasks,
  reassignTargetTask,
  receiptRccuTargetTask,
  receiptTargetTask,
  registerKeycloakSession,
  relistTargetingTask,
  saveCredibilityChecksAndAdditionalContent,
  saveTaskNote,
  stageTaskDismissal,
  assignTargetTask,
  submitForXrayAnalysis,
  submitInitialPnrAccessForm,
  submitTargetTaskFeedback,
  submitTargetInformationSheet,
  unCaptureTargetTask,
  unStageTaskDismissal,
  unclaimTask,
  updateGeneralAviationTask,
  updateIssuedTarget,
  updateTaskContent,
  updateTaskNote,
  withdrawTargetAndCompleteTask,
  withdrawTargetingTask,
};
