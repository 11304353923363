import { useEffect } from 'react';

const TAB_TITLES = {
  ACCESSIBILITY_STATEMENT: 'Accessibility statement for Central Operations Platform (COP) Cerberus',
  ADD_PNC_RESULTS: 'Add PNC check results',
  ADD_TARGET_SHEET_RECIPIENT: 'Add target sheet recipient',
  ADD_TASK_DETAIL: 'Add task detail',
  ADD_TASK_NOTE: 'Add task note',
  ADMIN_PANEL: {
    HOME: 'Admin panel',
    DELETE_TASK: 'Delete a task',
    CLEAR_DOWN_TARGETS: 'Clear down targets',
    DOWNLOAD_MOVEMENT_RECORD: 'Download movement record',
    MIGRATIONS: 'Migrate tasks & targets',
    VERSIONS: 'Versions',
  },
  ALERT_SENT_TO: 'Alert sent',
  ALERT_UPDATED_REISSUED: 'Alert updated and reissued',
  ASSESSMENT_COMPLETE: 'Assessment complete',
  CHANGE_TARGET_SHEET_RECIPIENT: 'Change target sheet recipient',
  CREATE_TARGET: 'Create target',
  DISMISS_TASK: 'Dismiss task',
  DO_YOU_WANT_TO_VIEW_PNR: 'Do you need to view Passenger Name Record (PNR) data?',
  EDIT_TARGET: 'Edit target',
  EDIT_TASK_DETAIL: 'Edit task detail',
  EDIT_TASK_NOTE: 'Edit task note',
  HANDLING_INSTRUCTIONS: 'Handling Instructions',
  MASK_PNR: 'PNR data will be masked until adequate justification to show it is provided',
  PREVIEW_TARGET_SHEET: 'Preview target sheet',
  PREVIOUS_TASK_DETAILS: 'Previous task details',
  RECIPIENT_DETAILS: 'Recipient Details',
  RELIST_TASK: 'Relist task',
  TARGET_CREATED: 'Target created successfully',
  TARGET_INFORMATION_SHEET: 'Target Information Sheet',
  TARGET_RELISTED: 'Target successfully relisted',
  TARGET_SENT_TO: 'Target sent',
  TARGET_UPDATED_REISSUED: 'Target updated and reissued',
  TARGET_WITHDRAW: 'Provide a reason for withdrawing the target',
  TARGET_WITHDRAWN: 'Target successfully withdrawn',
  TARGETS_LIST_PAGE: 'Targets - Central Operations Platform',
  TASKS_DISMISSED: 'Task(s) dismissed',
  TASK_COMPLETED: 'Task has been completed',
  TASK_DETAILS: 'Task details',
  TASK_DISMISSED: 'Task has been dismissed',
  UPDATE_TASK_DETAILS: 'Update task details',
  WITHDRAW_A_TARGET: 'Withdraw target',
  WITHDRAW_TARGET: 'Provide a reason for withdrawing the target',
  WORKING_FROM_APPROVED_SITE: 'Working from an approved access site',
};

const setTabTitle = (title) => {
  if (!title || typeof title !== 'string') {
    return;
  }

  document.title = title;
};

const useSetTabTitle = (title) => {
  useEffect(() => {
    setTabTitle(title);
  }, [title]);
};

export default useSetTabTitle;

export { setTabTitle, TAB_TITLES };
