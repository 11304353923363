// Global import(s)
import PropTypes from 'prop-types';
import { Heading } from '@ukhomeoffice/cop-react-components';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Component
import AdditionalContent from '../content/AdditionalContent';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import Itinerary from '../../../../../Task/Uplift/TaskDetails/components/shared/movement/voyage/shared/Itinerary';
import PreviousNext from '../generics/PreviousNext';

// Util(s)
import { CommonUtil, TargetCommonUtil } from '../../../../../../utils';
import BookingUtil from '../../../../../../utils/Booking/bookingUtil';
import DateTimeUtil from '../../../../../../utils/Datetime/datetimeUtil';
import ExtendedListRow from '../generics/ExtendedListRow';
import renderBlock, { toPreviousTaskLinkParams, toPreviousTaskLink } from '../../../../../Task/TaskDetails/helper/common';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';

import './BookingDetails.scss';

dayjs.extend(utc);

const BookingDetails = ({ informationSheet }) => {
  const timeDiff = DateTimeUtil.timeDiff([informationSheet?.movement?.booking?.bookedAt, dayjs.utc()], 'Booked', 'before travel');
  const booking = BookingUtil.get(informationSheet);
  const agent = BookingUtil.agent(booking);
  const agentIata = informationSheet?.movement?.booking?.agentIataCode;
  const journey = informationSheet?.movement?.journey;
  const itinerary = journey?.itinerary;
  const { width } = useWindowSize();
  const bookingAdditionalContents = TargetCommonUtil.additionalTextContents(booking);
  const journeyAdditionalContents = TargetCommonUtil.additionalTextContents(journey);
  const bookingCountryIso2Code = informationSheet?.movement?.booking?.bookingCountry?.isoCode;
  const bookingCountryIso3Code = CommonUtil.iso3Code(bookingCountryIso2Code);
  const formattedBookingCountryName = BookingUtil.format.countryName(bookingCountryIso2Code);

  const toSentenceCase = (str) => {
    return str?.length ? `${str[0].toUpperCase()}${str.substr(1).toLowerCase()}` : '';
  };

  const getAgentIATA = () => {
    return renderBlock(
      'Agent IATA',
      {
        content: agentIata,
      },
      false,
      toPreviousTaskLink(toPreviousTaskLinkParams(informationSheet, agent)),
    );
  };

  return (
    <div id="booking-details" className="govuk-grid-row target-info-summary-list">
      <hr />
      <PreviousNext
        id="booking-details"
        index={0}
        size={1}
      />
      <ComponentWrapper show={informationSheet?.movement?.booking}>
        <ComponentWrapper show={!isMobilePortrait(width)}>
          <Heading id="booking-details-header" size="l">Booking details</Heading>
        </ComponentWrapper>
        <ComponentWrapper show={isMobilePortrait(width)}>
          <h2 id="booking-details-header" className="ho-heading-m">Booking details</h2>
        </ComponentWrapper>
      </ComponentWrapper>
      <div className="govuk-grid-column-full">
        <div id="details" className="govuk-grid-row">
          <ComponentWrapper show={!isMobilePortrait(width)}>
            <ExtendedListRow
              id="booking"
              type="header-first-booking"
              values={[
                'Booking',
                timeDiff,
              ]}
            />
            <ExtendedListRow
              id="details"
              type="body"
              values={[
                ['Booking country', `${formattedBookingCountryName} - ${bookingCountryIso3Code}`],
                ['PNR locator', informationSheet?.movement?.booking?.pnrLocator],
                ['Ticket type', toSentenceCase(informationSheet?.movement?.booking?.ticketType)],
                getAgentIATA(),
              ]}
            />
          </ComponentWrapper>
          <ComponentWrapper show={isMobilePortrait(width)}>
            <ExtendedListRow
              id="booking"
              type="header-first-booking"
              values={[
                'Booking',
              ]}
            />
            <ExtendedListRow
              id="details"
              mobile
              type="body"
              values={[
                ['Booking country', `${formattedBookingCountryName} - ${bookingCountryIso3Code}`],
                ['PNR locator', informationSheet?.movement?.booking?.pnrLocator],
              ]}
            />
            <ExtendedListRow
              id="details"
              mobile
              type="body"
              values={[
                ['Ticket type', toSentenceCase(informationSheet?.movement?.booking?.ticketType)],
                getAgentIATA(),
              ]}
            />
          </ComponentWrapper>
          <AdditionalContent id="booking-additional-content-container" additions={bookingAdditionalContents} heading="Addition" />
          <Itinerary itinerary={itinerary} />
          <AdditionalContent id="journey-additional-content-container" additions={journeyAdditionalContents} heading="Addition" />
        </div>
      </div>
    </div>
  );
};

BookingDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(BookingDetails);
