import { Heading, Link } from '@ukhomeoffice/cop-react-components';
import { withCount } from 'pluralise';
import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Config(s)
import { TIME_FORMATS } from '../../../../../../../../utils/constants';

// Components(s)
import AgentIataModal from './AgentIataModal';
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ContactText from '../../contact/ContactText';
import Contents from '../../content/Contents';
import Details from '../../generics/Details';
import HeaderModule from '../../generics/HeaderModule';
import LabelValuePair from '../../generics/LabelValuePair';
import ValueField from '../../generics/ValueField';

// Util(s)
import BookingUtil from '../../../../../../../../utils/Booking/bookingUtil';
import CommonUtil from '../../../../../../../../utils/Common/commonUtil';
import DateTimeUtil from '../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import StringUtil from '../../../../../../../../utils/String/stringUtil';
import UpliftedCommonUtil from '../../../../../../../../utils/Common/Uplift/commonUtil';
import { shouldHideObjectArrayByAttr } from '../../../../../../../../utils/Hiding/hidingUtil';

dayjs.extend(utc);

const Booking = ({ booking, bookingVsIntent, totalPersons, movementId, movementIds }) => {
  const additionalContent = BookingUtil.additionalContent(booking);
  const iso2CountryCode = BookingUtil.bookingCountry(booking);
  const formattedCountryName = BookingUtil.format.countryName(iso2CountryCode);
  const iso3CountryCode = UpliftedCommonUtil.iso3Code(iso2CountryCode);
  const bookingType = BookingUtil.bookingType(booking);
  const pnrLocator = BookingUtil.bookingRef(booking);
  const tickets = BookingUtil.tickets(booking);
  const formattedTicketTypes = BookingUtil.format.ticketTypes(tickets);
  const dateBooked = BookingUtil.bookedAt(booking);
  const formattedDateBooked = DateTimeUtil.format.asUTC(dateBooked, TIME_FORMATS.UTC, TIME_FORMATS.CHECK_IN);
  const agent = BookingUtil.agent(booking);
  const formattedAgentDetails = StringUtil.format.address(BookingUtil.agentAddress(agent), true);
  const agentIata = BookingUtil.agentIata(agent);
  const telephoneNumbers = [BookingUtil.agentMobileContact(agent), BookingUtil.agentTelephoneContact(agent)].filter((contact) => !!contact).join(', ');
  const payments = BookingUtil.payments(booking);
  const otherTasksListURL = UpliftedCommonUtil.otherTasksListURL(UpliftedCommonUtil.otherTasksLinkParams(movementId, agent, movementIds));
  const otherTasksURLLabel = UpliftedCommonUtil.otherTasksListLabel(agent);
  const contactTextMatches = BookingUtil.contactTextMatches(booking);
  const rawPnrUrl = BookingUtil.rawPnrUrl(booking);

  const shouldShowPayments = () => {
    return !shouldHideObjectArrayByAttr(payments, 'type');
  };

  const toPaymentText = (payment) => {
    const card = BookingUtil.paymentCard(payment);
    const expiry = BookingUtil.cardExpiry(card);
    const formattedExpiry = BookingUtil.format.cardExpiry(expiry);
    const number = BookingUtil.cardLastFourDigits(card);
    const amount = BookingUtil.paymentAmount(payment);

    if (payment.type !== 'CC') {
      // Default to the payment type
      return payment.type;
    }

    return `${amount} Card ****${number}, exp. ${formattedExpiry}`;
  };

  const toPayments = () => {
    if (!payments?.length) {
      return [];
    }

    if (shouldHideObjectArrayByAttr(payments, 'type')) {
      return null;
    }

    return CommonUtil
      .uniqueId(payments)
      .map((payment) => {
        return (
          <>
            <span key={payment.id} id={`payment-${payment.id}`}>{toPaymentText(payment)}</span>
            <br />
          </>
        );
      });
  };

  const toAgentDetails = () => {
    if (!formattedAgentDetails) {
      return null;
    }
    const splitParts = formattedAgentDetails.split(',');
    return splitParts.map((part) => {
      return (
        <>
          <span>{part}</span>
          <br />
        </>
      );
    });
  };

  const toBookingVsIntent = () => {
    const timeDiff = DateTimeUtil.timeDiff([booking?.bookedAt, dayjs.utc()]).replace(/before travel/gi, '');
    return `BVI: ${timeDiff}`;
  };

  return (
    <div id="booking-module" className="govuk-grid-column-full">
      <HeaderModule
        id="booking-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="booking-header" size="m">Booking</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={bookingVsIntent}>
                <div className="grid-item">
                  <ValueField id="booking-bvi" value={toBookingVsIntent()} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={totalPersons}>
                <div className="grid-item">
                  <ValueField id="booking-passenger-count" value={withCount(totalPersons, '% passenger', '% passengers')} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={rawPnrUrl}>
                <div className="grid-item">
                  <Link id="raw-pnr-link" href={rawPnrUrl} target="_blank" rel="noreferrer noopener">
                    View raw PNR
                  </Link>
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={
          [
            {
              content: (
                <ComponentWrapper key="1" show={iso2CountryCode && iso3CountryCode && formattedCountryName}>
                  <div className="grid-item">
                    <LabelValuePair id="booking-country" label="Booking country" value={[formattedCountryName, iso3CountryCode].join(' - ')} />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="2" show={bookingType}>
                  <div className="grid-item">
                    <LabelValuePair id="booking-type" label="Booking type" value={bookingType} />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="3" show={pnrLocator}>
                  <div className="grid-item">
                    <LabelValuePair id="booking-pnr-locator" label="PNR locator" value={pnrLocator} />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="4" show={formattedTicketTypes}>
                  <div className="grid-item">
                    <LabelValuePair id="booking-ticket-type" label="Ticket type" value={formattedTicketTypes} />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="5" show={dateBooked && formattedDateBooked}>
                  <div className="grid-item">
                    <LabelValuePair id="booking-booked-at" label="Booked" value={formattedDateBooked} />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="6" show={agent && formattedAgentDetails}>
                  <div className="grid-item">
                    <LabelValuePair
                      id="booking-agent-details"
                      label="Agent details"
                      value={toAgentDetails()}
                      secondaryValue={
                        (otherTasksListURL && otherTasksURLLabel) && (
                          <Link id="booking-agent-other-tasks" href={otherTasksListURL} target="_blank" rel="noreferrer noopener">
                            {otherTasksURLLabel}
                          </Link>
                        )
                      }
                    />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="7" show={agent && agentIata}>
                  <div className="grid-item">
                    <LabelValuePair
                      id="booking-agent-iata"
                      label="Agent IATA"
                      value={<AgentIataModal id="booking-agent-iata" agentIata={agentIata} />}
                    />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="8" show={agent && telephoneNumbers}>
                  <div className="grid-item">
                    <LabelValuePair id="booking-agent-telephone" label="Phone number" value={telephoneNumbers} />
                  </div>
                </ComponentWrapper>
              ),
            },
            {
              content: (
                <ComponentWrapper key="9" show={payments && payments.length && shouldShowPayments()}>
                  <div className="grid-item grid-item-span-2">
                    <LabelValuePair id="booking-payments" label="Payments" value={toPayments()} />
                  </div>
                </ComponentWrapper>
              ),
            },
          ]
        }
      />
      <ContactText contactTextMatches={contactTextMatches} />
      <Contents id="booking-contents" contents={additionalContent} />
    </div>
  );
};

Booking.propTypes = {
  booking: PropTypes.shape({}).isRequired,
  bookingVsIntent: PropTypes.number,
  totalPersons: PropTypes.number.isRequired,
  movementId: PropTypes.string.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Booking.defaultProps = {
  bookingVsIntent: 0,
};

export default Booking;
