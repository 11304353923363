import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Config
import { TARGET_OPERATION } from '../../../../../../../../utils/constants';

// Component(s)
import LoadingSpinner from '../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../../../../components/RenderForm/RenderForm';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import addDataToComponent from '../helper/addDataToComponent';
import containsRecipient from '../validate/containsRecipient';
import { Renderers } from '../../../../../../../../utils/Form/ReactForm';

// Form(s)
import targetRecipient from '../../../../../../../../forms/uplift/targetRecipient';

const CommoditiesAddRecipient = ({ actionType, previouslySelectedRecipient, onSave, formData, setFormData, onCancel, targetRecipientOptions }) => {
  const [form, setForm] = useState(null);

  setTabTitle(TAB_TITLES.ADD_TARGET_SHEET_RECIPIENT);

  const onInternalSaveAndClose = (data) => {
    if (containsRecipient(formData, data)) {
      // Do not save the data, instead call the onSave callback.
      if (typeof onSave === 'function') {
        onSave();
        return;
      }
    }

    const saveTargetRecipients = (prev) => {
      if (data?.FRONTLINE_TEAM) {
        return {
          targetRecipients: [
            ...(prev?.targetRecipients ? prev.targetRecipients : []),
            data?.FRONTLINE_TEAM,
            ...(data?.frontlineRecipients.map((team) => {
              return team.FRONTLINE_TEAM;
            })),
          ],
        };
      }
      return {
        targetRecipients: [
          ...(prev?.targetRecipients ? prev.targetRecipients : []),
        ],
      };
    };

    const saveBorderOfficers = (prev) => {
      if (data?.BORDER_OFFICER) {
        return {
          borderOfficers: [
            ...(prev?.borderOfficers ? prev.borderOfficers : []),
            data?.BORDER_OFFICER,
            ...(data?.borderOfficers.map((officer) => officer.BORDER_OFFICER)),
          ],

        };
      }
      return {
        borderOfficers: [
          ...(prev?.borderOfficers ? prev.borderOfficers : []),
        ],
      };
    };

    setFormData((prev) => {
      return {
        ...prev,
        ...(saveBorderOfficers(prev)),
        ...(saveTargetRecipients(prev)),
      };
    });

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  useEffect(() => {
    // Setup component options
    let _form = {
      ...targetRecipient,
    };
    _form = addDataToComponent(_form, 'FRONTLINE_TEAM', targetRecipientOptions);
    setForm(_form);
  }, []);

  useEffect(() => {
    return () => setTabTitle(actionType === TARGET_OPERATION.CREATE ? TAB_TITLES.CREATE_TARGET : TAB_TITLES.EDIT_TARGET);
  }, []);

  if (!form) {
    return <LoadingSpinner />;
  }

  return (
    <div id="add-recipient" className={classNames('govuk-grid-row', previouslySelectedRecipient && 'option-divider')}>
      <div className="govuk-grid-column-one-third">
        <RenderForm
          form={form}
          viewOnly={false}
          onSubmit={async ({ data }) => onInternalSaveAndClose(data)}
          renderer={Renderers.REACT}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

CommoditiesAddRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  previouslySelectedRecipient: PropTypes.string,
  formData: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  targetRecipientOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

CommoditiesAddRecipient.defaultProps = {
  previouslySelectedRecipient: null,
  targetRecipientOptions: [],
};

export default CommoditiesAddRecipient;
